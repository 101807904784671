
import React, { useEffect, useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';  // InputTextarea로 변경
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown'; // Dropdown 컴포넌트 import
import { Prism } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { ButtonGroup } from 'primereact/buttongroup';

import io from 'socket.io-client';

import '../chat.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import axios from 'axios';

import { debounce } from 'lodash';

const ChatPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { course, token } = useSelector((state) => state.AuthReducer);

    const socket = io('https://ihongss.com:11883', {
        query: {
            course: course
        }
    });

    const selectLangs = [
        { label: 'java', value: 'java' },
        { label: 'text', value: 'text' },
        { label: 'image', value: 'image' },
        { label: 'html', value: 'html' },
        { label: 'javascript', value: 'javascript' },
        { label: 'python', value: 'python' },
    ];

    const [rows, setRows] = useState([]);
    const [message, setMessage] = useState('');
    const [lang, setLang] = useState('java');
    const [page, setPage] = useState(0);
    const [pageChk, setPageChk] = useState(false);
    const [imageChk, setImageChk] = useState(false);
    const [imageSrc, setImageSrc] = useState(`${process.env.PUBLIC_URL}/default.jpeg`);

    useEffect(() => {
        connectSocketIO();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        handleReadMessage();
    }, [pageChk]);


    const connectSocketIO = () => {
        socket.on('publish', (recv) => {
            if (recv.message.trim()) {
                setRows((prevMessages) => [{ lang: recv.lang, message: recv.message, date: recv.date, copied: false }, ...prevMessages]);
            }
        });

        return () => {
            socket.off('publish');
        };
    };


    const handleSendMessage = (e) => {
        e.preventDefault();

        if (imageChk) {
            socket.emit('publish', { lang: lang, message: imageSrc, course: course });
            setMessage('');
            setImageSrc(`${process.env.PUBLIC_URL}/default.jpeg`);
        }
        else {
            if (message.trim()) {
                socket.emit('publish', { lang: lang, message: message, course: course });
                setMessage('');
            }
        }
    };

    const handleListPage = () => {
        navigate(`/list`);
    };

    const handleBoardPage = () => {
        navigate(`/board`);
    };

    const handleLogout = () => {
        if (window.confirm('로그아웃 할까요??')) {
            dispatch({ type: 'logout' });
            navigate(`/`);
        }
    };


    const handleCopy = (idx) => {
        const updatedRows = rows.map((row, index) => ({
            ...row,
            copied: index === idx ? true : false,
        }));

        setRows(updatedRows);

        setTimeout(() => {
            const resetRows = rows.map((row) => ({
                ...row,
                copied: false,
            }));
            setRows(resetRows);
        }, 1000);
    };

    const handleReadMessage = async () => {
        const url = `/api/member/chat_list_v2.json?page=${page + 1}`;
        const headers = { "Content-Type": "application/json", "auth": token };
        const { data } = await axios.get(url, { headers });
        // console.log(data);
        if (data.result.length > 0) {
            // 가져올 내용이 있으면 page의 숫자 증가
            setPage(prevPage => prevPage + 1);
            for (let recv of data.result) {
                setRows((prevMessages) => [...prevMessages, { lang: recv.lang, message: recv.message, copied: false, date: recv.date }]);
            }
        }
    };


    const handleScroll = debounce(() => {
        const threshold = 5;
        const isBottom = window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - threshold;
        //console.log(window.innerHeight , document.documentElement.scrollTop, document.documentElement.offsetHeight);

        if (isBottom) {
            setPageChk(prevState => !prevState);
        }
    }, 200);

    const handleChatPage = () => {
        navigate(`/chat`);
    };

    const handleNoticePage = () => {
        navigate(`/notice`);
    };

    const changeLang = (e) => {
        setLang(e.target.value);
        if (e.target.value === "image") {
            setImageChk(true);
        }
        else {
            setImageChk(false);
        }
    }

    const changeMessage = (e) => {
        setMessage(e.target.value)
    }

    const handleImagePaste = (e) => {
        const clipboardData = e.clipboardData || e.originalEvent.clipboardData;
        const items = clipboardData.items;

        if (items) {
            for (let i = 0; i < items.length; i++) {
                if (items[i].type.indexOf('image') !== -1) {
                    const file = items[i].getAsFile();
                    const reader = new FileReader();

                    reader.onloadend = () => {
                        setImageSrc(reader.result);
                    };

                    if (file) {
                        reader.readAsDataURL(file);
                    }
                    e.preventDefault();
                }
            }
        }
    }



    return (
        <div>
            <div className="card flex justify-content-center">
                <ButtonGroup>
                    <Button onClick={handleChatPage} severity="secondary">실시간메시지</Button>&nbsp;
                    <Button onClick={handleListPage}>강의자료</Button>&nbsp;
                    <Button onClick={handleBoardPage}>게시판</Button>&nbsp;
                    <Button onClick={handleNoticePage}>공지사항</Button>&nbsp;
                    <Button onClick={handleLogout}>로그아웃</Button>
                </ButtonGroup>
            </div>
            <hr />

            <p style={{ color: "red" }}>*** 오래된 메시지는 자동으로 삭제 됩니다.</p>

            <div className="chat-container">
                <form onSubmit={handleSendMessage} method="post" style={{ width: '100%' }}>
                    <div className="input-container">

                        <Dropdown
                            value={lang} // 현재 선택된 값
                            options={selectLangs} // 옵션 리스트
                            onChange={(e) => changeLang(e)} // 값 변경 시 호출되는 함수
                            placeholder="언어선택" // 기본 표시 텍스트
                        />

                        {imageChk && (
                            <img
                                src={imageSrc}
                                style={{ width: '200px' }}
                                alt="image"
                            />
                        )}

                        <InputTextarea
                            value={message}
                            onPaste={handleImagePaste}
                            onChange={(e) => changeMessage(e)}
                            placeholder="메시지, 이미지를 입력하세요."
                            rows={5}
                            cols={30}
                            autoResize={false}
                            style={{ flex: 1, resize: 'none' }}
                        />

                        <Button
                            type="submit"
                            label="보내기"
                            icon="pi pi-send"
                            size="small"
                            style={{ width: 'auto' }}
                            severity="info"
                            onClick={handleSendMessage}
                        />
                    </div>
                </form>

                <div style={{ width: '100%' }}>
                    {
                        rows.map((obj, index) => (
                            <div key={index} style={{ position: 'relative', width: '100%' }}>
                                <div style={{ border: '1px soild #cccccc' }}>

                                    {obj.lang !== "image" ? (
                                        <>
                                            <p style={{ marginBottom: '-3px' }}>{obj.date}</p>
                                            <Prism language={obj.lang} style={vs} customStyle={{ fontSize: '18px' }}>
                                                {obj.message}
                                            </Prism>

                                            <CopyToClipboard text={obj.message} onCopy={() => { handleCopy(index) }}>
                                                <button
                                                    style={{
                                                        position: 'absolute',
                                                        top: 30,
                                                        right: 10,
                                                        padding: '8px 12px',
                                                        backgroundColor: '#282c34',
                                                        color: '#fff',
                                                        border: 'none',
                                                        cursor: 'pointer',
                                                        borderRadius: '5px',
                                                    }}>
                                                    {obj.copied ? '복사완료' : '복사'}
                                                </button>
                                            </CopyToClipboard>
                                        </>
                                    ) : (
                                        <img src={obj.message} style={{ marginTop: '10px', display: 'block', maxWidth: '100%', height: 'auto' }} />
                                    )}

                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <footer>
                <div className="container">
                    <div className="row">
                        <p className="copyright text-muted">2016 &copy; ihongss.com</p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default ChatPage;